<template>
  <section class="section main-section">
    <h4 class="section__title section__title_centered mb-1">Трансфер денег</h4>
    <GoBackIcon back-route-name="main.home" />
    <q-form @submit.prevent="postTransfer" class="q-mt-lg transfer-form">
      <div class="transfer-form-block">
        <q-input
          filled
          v-model="fromUsersLedger"
          type="number"
          label="Лицевой счет отправителя"
        >
        </q-input>

        <q-input
          class="q-mt-sm"
          filled
          v-model="toUsersLedger"
          type="number"
          label="Лицевой счет получателя"
        >
        </q-input>

        <q-input label="Сумма" type="number" v-model.number="sumToTransfer" />
        <q-btn
          class="q-mt-sm"
          type="submit"
          color="primary"
          no-caps
          :loading="isLoading"
          :disable="isSubmitDisabled"
          label="Перевести"
        />
      </div>
    </q-form>
  </section>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import GoBackIcon from "@/components/icon-components/GoBackIcon.vue";
import usersApi from "@/api/user.api";
import transactionsApi from "@/api/transactions.api";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "MoneyTransferPage",
  components: {
    GoBackIcon,
  },

  setup() {
    const router = useRouter();
    const isLoading = ref(false);
    const fromUsersLedger = ref("");
    const toUsersLedger = ref("");
    const foundUsersList = ref([]);
    const sumToTransfer = ref(null);

    const isSubmitDisabled = computed(() => {
      return (
        !fromUsersLedger.value || !toUsersLedger.value || !sumToTransfer.value
      );
    });

    const postTransfer = async () => {
      try {
        if (!fromUsersLedger.value.length || !toUsersLedger.value.length)
          return;
        const firstResult = await usersApi.getUserIdByLedger(
          fromUsersLedger.value
        );
        const secondResult = await usersApi.getUserIdByLedger(
          toUsersLedger.value
        );
        isLoading.value = true;
        await transactionsApi.postTransfer({
          from_user_id: firstResult.data.id,
          to_user_id: secondResult.data.id,
          sum: sumToTransfer.value,
        });
        await router.push({ name: "main.home" });
        alert("Операция по переводу денег было успешно выполнено");
      } catch (e) {
        if (e.response.data) {
          const data = e.response.data;
          if (data.message?.sum) {
            alert("У пользователя недостаточно денег для перевода");
          } else if (data.statusCode === 404) {
            alert("Не найден пользователь");
          }
        }
      } finally {
        isLoading.value = false;
      }
    };

    return {
      isLoading,
      fromUsersLedger,
      toUsersLedger,
      foundUsersList,
      sumToTransfer,
      isSubmitDisabled,
      postTransfer,
    };
  },
});
</script>

<style lang="scss">
.transfer-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &-block {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 250px;
  }
}
</style>
