<template>
  <section class="section main-section">
    <h4 class="section__title section__title_centered">Транзакции</h4>
    <GoBackIcon back-route-name="main.home" class="mb-1" />
    <q-form @submit="addQuery" class="row align-center q-gutter-md">
      <q-input
        filled
        v-model="phoneNumber"
        label="Номер телефона"
        hint="Введите номер пользователя"
        lazy-rules
        prefix="+7 "
        mask="phone"
      />

      <q-input
        filled
        v-model="ledgerCode"
        label="Лицевой счет"
        hint="Введите лицевой счет"
        mask="### ### ####"
      />

      <q-input
        filled
        v-model="bin"
        label="ИИН/БИН"
        hint="Введите БИН"
        mask="### ### ### ###"
      />

      <div>
        <q-btn
          label="Поиск"
          type="submit"
          color="primary"
          :loading="isLoading"
        />
      </div>
    </q-form>
    <div v-if="transactionsList.length" class="user-info">
      <q-table
        :columns="columns"
        :rows="transactionsList"
        row-key="name"
        hide-no-data
        style="width: 100%"
      />
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from "vue";
import GoBackIcon from "@/components/icon-components/GoBackIcon.vue";
import { useRoute, useRouter } from "vue-router";
import { useQuasar } from "quasar";
import transactionsApi from "@/api/transactions.api";

export default defineComponent({
  name: "TransactionsPage",
  components: {
    GoBackIcon,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const $q = useQuasar();

    const columns = [
      {
        name: "phone_number",
        required: true,
        label: "Номер телефона",
        align: "left",
        field: (row) => row.phone_number,
        format: (val) => `${val}`,
      },
      {
        name: "code",
        align: "center",
        label: "Лицевой счет",
        field: "code",
      },
      {
        name: "provider",
        align: "center",
        label: "Провайдер",
        field: "provider",
      },
      {
        name: "sum",
        align: "center",
        label: "Сумма",
        field: "sum",
      },
      {
        name: "created_at",
        align: "right",
        label: "Дата транзакции",
        field: "created_at",
        format: (val) => formatDate(val),
      },
    ];

    const transactionsList = ref([]);
    const phoneNumber = ref("");
    const bin = ref("");
    const ledgerCode = ref("");

    const isLoading = ref(false);

    const formatDate = (date) => {
      return new Intl.DateTimeFormat("ru-RU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(new Date(date));
    };

    const addQuery = async () => {
      if (!phoneNumber.value && !bin.value && !ledgerCode.value) {
        return $q.notify({
          message: "Необходимо выбрать один из параметров поиска",
          position: "top-right",
          timeout: 2000,
          icon: "error",
        });
      }

      if (phoneNumber.value)
        await router.push({ query: { phone: phoneNumber.value } });
      else if (bin.value)
        await router.push({ query: { bin: bin.value.replace(/[ ]/g, "") } });
      else if (ledgerCode.value)
        await router.push({ query: { code: ledgerCode.value } });
      await fetchUsersTransactions();
    };

    const fetchUsersTransactions = async () => {
      let query = "";
      if (phoneNumber.value) {
        const formattedPhoneNumber =
          "7" + phoneNumber.value.replace(/[()\- ]/g, "");
        query = `phone=${formattedPhoneNumber}`;
      } else if (bin.value) query = `bin=${bin.value.replace(/[ ]/g, "")}`;
      else if (ledgerCode.value)
        query = `code=${ledgerCode.value.replace(/[ ]/g, "")}`;

      if (!query) return;
      try {
        isLoading.value = true;
        const { data } = await transactionsApi.getUsersAllTransactions(query);
        transactionsList.value = data;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      if (route.query.phone) phoneNumber.value = route.query.phone;
      else if (route.query.bin) bin.value = route.query.bin;
      else if (route.query.code) ledgerCode.value = route.query.code;
      if (phoneNumber.value || bin.value || ledgerCode.value)
        await fetchUsersTransactions();
    });

    return {
      columns,
      transactionsList,
      phoneNumber,
      ledgerCode,
      bin,
      isLoading,
      fetchUsersTransactions,
      addQuery,
    };
  },
});
</script>

<style lang="scss">
.user-info {
  display: flex;
  gap: 50px;
  margin-top: 35px;
}
</style>
