
import { defineComponent } from "vue";
export default defineComponent({
  name: "GoBackIcon",
  props: {
    backRouteName: {
      type: String,
      required: true,
    },
  },
  methods: {
    back() {
      this.$router.push({ name: this.backRouteName });
    },
  },
});
