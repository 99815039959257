import request from "@/api/request";

export default {
  getUserDetails: (body: any) => request.post("users", body),
  getUserDetailsById: (id: string) => request.get(`users/${id}`),
  update: (id: number, data: any) => request.patch(`users/${id}`, data),
  sendNotification: (data: any) => request.post("notifications", data),
  getUsersAuthCode: (id: string) => request.get(`auth-code/users-code/${id}`),
  setUsersAuthCode: (code: string, userId: number) =>
    request.post(`auth-code/set-users-code/${userId}`, { code }),
  getUserIdByLedger: (ledger: string) =>
    request.post("users/search", { ledger }),
};
