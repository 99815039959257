<template>
  <section class="section">
    <h4 class="section__title section__title_centered">Обратная связь</h4>
    <GoBackIcon back-route-name="main.home" class="mb-1" />
    <div class="flex justify-end q-mb-md">
      <q-toggle
        v-model="showOnlyUnread"
        checked-icon="check"
        color="green"
        unchecked-icon="clear"
        label="Показать только непрочитанные"
      />
    </div>
    <q-table
      :loading="isLoading"
      :columns="columns"
      :rows="list"
      row-key="id"
      hide-no-data
      style="width: 100%"
      :pagination="pagination"
      hide-pagination
    >
      <template v-slot:body-cell-action="props">
        <q-tr :props="props">
          <q-td key="action" :props="props" class="full-width">
            <q-btn color="grey-7" round flat icon="more_vert">
              <q-menu cover auto-close>
                <q-list>
                  <q-item @click="readMessage(props.row)" clickable>
                    <q-item-section>Прочитать</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn></q-td
          >
        </q-tr>
      </template>
    </q-table>
    <div class="row justify-center q-mt-md">
      <q-pagination
        v-model="pagination.page"
        color="grey-8"
        :max="pagesNumber"
        :max-pages="6"
        size="sm"
        @update:model-value="changePage"
      />
    </div>
    <q-dialog v-model="showDetailsModal">
      <q-card>
        <div class="q-pa-md" style="max-width: 400px; min-width: 400px">
          <q-input
            filled
            v-model="selectedItem.phone_number"
            label="Номер телефона"
            readonly
          />
          <q-input filled v-model="selectedItem.name" label="Имя" readonly />
          <q-input
            v-model="selectedItem.text"
            filled
            label="Сообщение"
            autogrow
            readonly
          />
        </div>
      </q-card>
    </q-dialog>
  </section>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import fdmApi from "@/api/feedback-messages.api";
import GoBackIcon from "@/components/icon-components/GoBackIcon";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "FeedbackMessagesPage",
  components: {
    GoBackIcon,
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query.page) {
      return next({ name: to.name, query: { page: 1 } });
    }
    next();
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(false);
    const showOnlyUnread = ref(false);
    const selectedItem = ref(null);
    const showDetailsModal = ref(false);
    const columns = [
      {
        name: "phone_number",
        required: true,
        label: "Номер телефона",
        align: "left",
        field: (row) => row.phone_number,
        format: (val) => `${val}`,
      },
      {
        name: "name",
        align: "center",
        label: "Имя",
        field: "name",
      },
      {
        name: "email",
        align: "center",
        label: "Почта",
        field: "email",
      },
      {
        name: "created_at",
        align: "center",
        label: "Дата обращения",
        field: "created_at",
        format: (val) => formatDate(val),
      },
      {
        name: "is_unread",
        align: "center",
        label: "Прочтено",
        field: "is_unread",
        format: (val) => (val ? "Нет" : "Да"),
      },
      {
        name: "action",
        align: "left",
        label: "Действия",
        field: "action",
      },
    ];

    watch(showOnlyUnread, async (newVal) => {
      await fetchMessages();
    });

    const pagination = ref({
      page: Number(route.query.page),
      rowsPerPage: 20,
    });

    const pagesNumber = ref(5);

    const formatDate = (date) => {
      return new Intl.DateTimeFormat("ru-RU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(new Date(date));
    };

    const changePage = async (newPage) => {
      await router.push({ query: { page: newPage } });
      await fetchMessages();
    };

    const list = ref([]);

    const getQueryForRequest = () => {
      let query = `page=${pagination.value.page}`;
      if (showOnlyUnread.value) query += `&show-only-unread=1`;
      return query;
    };

    const fetchMessages = async () => {
      try {
        isLoading.value = true;
        const { data } = await fdmApi.fetchFeedbackMessages(
          getQueryForRequest()
        );
        pagination.value.page = Number(data?.current_page) || 1;
        pagination.value.rowsPerPage = Number(data?.per_page) || 20;
        pagesNumber.value = Number(data?.last_page) || 1;
        list.value = data.data;
      } finally {
        isLoading.value = false;
      }
    };

    const readMessage = async (item) => {
      if (item.is_unread) {
        await fdmApi.setMsgStatusRead(item.id);
        list.value.forEach((el) => {
          if (el.id === item.id) el.is_unread = false;
        });
      }
      selectedItem.value = item;
      showDetailsModal.value = true;
    };

    onMounted(async () => {
      await fetchMessages();
    });

    return {
      showOnlyUnread,
      isLoading,
      columns,
      list,
      pagination,
      pagesNumber,
      selectedItem,
      showDetailsModal,
      changePage,
      readMessage,
    };
  },
});
</script>
